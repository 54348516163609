import { CornerMarkerState, MarkerClick } from '../types';

export const markerClick = (state: CornerMarkerState, action: MarkerClick) => {
    const markerClickKey = `${action.payload.gameId}#${action.payload.trackId}#${action.payload.segment}`;
    const segmentConfig = state.markers[markerClickKey];

    if (action.payload.remove) {
        const newMarkers = segmentConfig.markers.filter((_, i) => i !== action.payload.markerIndex);
        return {
            ...state,
            markers: {
                ...state.markers,
                [markerClickKey]: {
                    activeMarkerIndex: 0,
                    markers: newMarkers,
                    addingMarker: segmentConfig.addingMarker
                }
            }
        };
    }

    const markerClickMarkers = segmentConfig.markers ?? [];

    return {
        ...state,
        markers: {
            ...state.markers,
            [markerClickKey]: {
                activeMarkerIndex: action.payload.markerIndex,
                markers: markerClickMarkers,
                addingMarker: segmentConfig.addingMarker
            }
        }
    };
};
