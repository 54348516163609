import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import createStorageSlice, { StorageSlice, StorageValuesSlice, storageInitialValuesState } from './storage';
import createAppSlice, { AppSlice, AppValuesSlice, appInitialValuesState } from './app';
import createAcademySlice, { AcademySlice, AcademyValuesSlice, academyInitialValuesState } from './academy';
import createBreadcrumbsSlice, {
    BreadcrumbsSlice,
    BreadcrumbsValuesSlice,
    breadcrumbsInitialValuesState
} from './breadcrumbs';
import createAuthSlice, { AuthSlice, AuthValuesSlice, authInitialValuesState } from './auth';
import createAnalysisSlice, { AnalysisSlice, AnalysisValuesSlice, analysisInitialValuesState } from './analysis';
import createSetupsSlice, { SetupsSlice, SetupsValuesSlice, setupsInitialValuesState } from './setups';
import createContentRevealedSlice, {
    ContentRevealedSlice,
    ContentRevealedValuesSlice,
    contentRevealedInitialValuesState
} from './content-revealed';
import createRaceEngineerSlice, {
    raceEngineerInitialValuesState,
    RaceEngineerSlice,
    RaceEngineerValuesSlice
} from './race-engineer';
import createBlogsSlice, { BlogsSlice, BlogsValuesSlice, blogsInitialValuesState } from './blog';
import createCornerMarkerSlice, {
    CornerMarkerSlice,
    CornerMarkerValuesSlice,
    cornerMarkerInitialValuesState
} from './corner-marker';
import createRemotionSlice, { RemotionSlice, RemotionValuesSlice, remotionInitialValuesState } from './remotion';

export type RootState = StorageSlice &
    AppSlice &
    AcademySlice &
    BreadcrumbsSlice &
    AuthSlice &
    AnalysisSlice &
    SetupsSlice &
    ContentRevealedSlice &
    RaceEngineerSlice &
    BlogsSlice &
    CornerMarkerSlice &
    RemotionSlice;

export type RootValuesState = {
    storage: StorageValuesSlice;
    app: AppValuesSlice;
    academy: AcademyValuesSlice;
    breadcrumbs: BreadcrumbsValuesSlice;
    auth: AuthValuesSlice;
    analysis: AnalysisValuesSlice;
    setups: SetupsValuesSlice;
    contentRevealed: ContentRevealedValuesSlice;
    raceEngineer: RaceEngineerValuesSlice;
    blogs: BlogsValuesSlice;
    cornerMarker: CornerMarkerValuesSlice;
    remotion: RemotionValuesSlice;
};

export const initialValuesState: RootValuesState = {
    storage: storageInitialValuesState,
    app: appInitialValuesState,
    academy: academyInitialValuesState,
    breadcrumbs: breadcrumbsInitialValuesState,
    auth: authInitialValuesState,
    analysis: analysisInitialValuesState,
    setups: setupsInitialValuesState,
    contentRevealed: contentRevealedInitialValuesState,
    raceEngineer: raceEngineerInitialValuesState,
    blogs: blogsInitialValuesState,
    cornerMarker: cornerMarkerInitialValuesState,
    remotion: remotionInitialValuesState
};

export const useTrackTitanStore = create<RootState>()(
    devtools(
        persist(
            (set, get, api) => ({
                ...createStorageSlice(set, get, api),
                ...createAppSlice(set, get, api),
                ...createAcademySlice(set, get, api),
                ...createBreadcrumbsSlice(set, get, api),
                ...createAuthSlice(set, get, api),
                ...createAnalysisSlice(set, get, api),
                ...createSetupsSlice(set, get, api),
                ...createContentRevealedSlice(set, get, api),
                ...createRaceEngineerSlice(set, get, api),
                ...createBlogsSlice(set, get, api),
                ...createCornerMarkerSlice(set, get, api),
                ...createRemotionSlice(set, get, api)
            }),
            {
                name: 'trackTitan',
                partialize: (state) => {
                    const output: Record<'storage' | 'analysis' | 'cornerMarker' | 'app', Record<string, unknown>> = {
                        storage: {
                            savedRoute: state.storage.savedRoute,
                            identitiesRoute: state.storage.identitiesRoute,
                            completedCountdown: state.storage.completedCountdown,
                            slide: state.storage.slide,
                            hasRegistered: state.storage.hasRegistered,
                            firstSessionAnalysed: state.storage.firstSessionAnalysed,
                            ghostEventDownloaded: state.storage.ghostEventDownloaded,
                            signupTime: state.storage.signupTime,
                            showReferralBanner: state.storage.showReferralBanner,
                            kphOrMph: state.storage.kphOrMph,
                            registerId: state.storage.registerId,
                            hasCameFromSetups: state.storage.hasCameFromSetups,
                            followTimestamp: state.storage.followTimestamp,
                            hasSeenIntercomSurveyQuickTips: state.storage.hasSeenIntercomSurveyQuickTips,
                            showSetupTooltipCars: state.storage.showSetupTooltipCars,
                            hasSentIntercomLoadedEvent: state.storage.hasSentIntercomLoadedEvent,
                            hasClickedPremiumPlanLink: state.storage.hasClickedPremiumPlanLink,
                            racingLineThickness: state.storage.racingLineThickness,
                            numberOfTimesViewedDisordButton: state.storage.numberOfTimesViewedDisordButton,
                            hasClickedDiscordButton: state.storage.hasClickedDiscordButton,
                            unauthEvents: state.storage.unauthEvents,
                            hasSeenDrivingAssessment: state.storage.hasSeenDrivingAssessment,
                            language: state.storage.language,
                            hasUpdatedUserType: state.storage.hasUpdatedUserType,
                            sanityInfoModalsOpened: state.storage.sanityInfoModalsOpened,
                            newsEngineerPersonalisation: state.storage.newsEngineerPersonalisation,
                            useBrakingPoints: state.storage.useBrakingPoints,
                            activationSelectedGame: state.storage.activationSelectedGame,
                            activationConfiguredGame: state.storage.activationConfiguredGame,
                            useMapRotation: state.storage.useMapRotation,
                            hasSeenDtwModal: state.storage.hasSeenDtwModal
                            // TODO enable local storage for showSetupTooltip
                            // showSetupTooltip: state.storage.showSetupTooltip
                        },
                        analysis: {
                            recentlySearched: state.analysis.recentlySearched,
                            favourites: state.analysis.favourites
                        },
                        cornerMarker: {
                            state: state.cornerMarker.state
                        },
                        app: {
                            referralCode: state.app.referralCode
                        }
                    };

                    return output;
                },
                merge: (persistedState: any, currentState) => {
                    return {
                        ...currentState,
                        storage: {
                            ...currentState.storage,
                            ...persistedState.storage
                        },
                        analysis: {
                            ...currentState.analysis,
                            ...persistedState.analysis
                        },
                        cornerMarker: {
                            ...currentState.cornerMarker,
                            ...persistedState.cornerMarker
                        },
                        app: {
                            ...currentState.app,
                            ...persistedState.app
                        }
                    };
                }
            }
        )
    )
);
