import { StateCreator, StoreApi } from 'zustand';

export interface RemotionValuesSlice {
    trackMapRect?: { width: number; height: number };
}

export interface RemotionActionsSlice {
    setTrackMapRect: (trackMapRect: { width: number; height: number }) => void;
}

export interface RemotionSlice {
    remotion: RemotionValuesSlice & RemotionActionsSlice;
}

export const remotionInitialValuesState: RemotionValuesSlice = {
    trackMapRect: undefined
};

const setSlice = (
    param: (state: RemotionSlice['remotion']) => Partial<RemotionSlice['remotion']>,
    set: (
        partial:
            | RemotionSlice
            | Partial<RemotionSlice>
            | ((state: RemotionSlice) => RemotionSlice | Partial<RemotionSlice>)
    ) => void
) =>
    set((state) => ({
        remotion: { ...state.remotion, ...param(state.remotion) }
    }));

export const createRemotionSlice: StateCreator<RemotionSlice> = (set) => ({
    remotion: {
        ...remotionInitialValuesState,
        setTrackMapRect: (trackMapRect: { width: number; height: number }) => setSlice(() => ({ trackMapRect }), set)
    }
});

export default createRemotionSlice as (
    set: StoreApi<RemotionSlice>['setState'],
    get: StoreApi<RemotionSlice>['getState'],
    api: StoreApi<RemotionSlice>
) => RemotionSlice;
