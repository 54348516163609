import { StateCreator, StoreApi } from 'zustand';

export interface UtmData {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_term?: string;
    utm_content?: string;
    utm_referral_code?: string;
}

export interface AppValuesSlice {
    rightSidebarWidth: number;
    testUserLastSessionId: string;
    referralCode: string;
    internalReferrer: string;
    setupReferral: string;
    userAgent: Bowser.Parser.PlatformDetails | undefined;
    utmData: UtmData;
    lastSessionSetup: boolean;
    achievementCurrentChallenge: boolean;
    vulcanMigration: boolean;
    remotion: boolean;
    showCodeOnRegister: boolean;
    redirectCodeToMainWebsite: boolean;
    newOnboardingFlow: boolean;
    showUnauthModal: boolean | null;
    currentActivationStage: number;
    furthestActivationStage: number;
    hymoFeatures: boolean;
}

export interface AppActionsSlice {
    setRightSidebarWidth: (rightSidebarWidth: number) => void;
    setTestUserLastSessionId: (testUserLastSessionId: string) => void;
    setReferralCode: (referralCode: string) => void;
    setInternalReferrer: (internalReferrer: string) => void;
    setSetupReferral: (setupReferral: string) => void;
    setUserAgent: (userAgent: Bowser.Parser.PlatformDetails) => void;
    setUtmData: (utmData: UtmData) => void;
    setLastSessionSetup: (lastSessionSetup: boolean) => void;
    setAchievementCurrentChallenge: (achievementCurrentChallenge: boolean) => void;
    setVulcanMigration: (vulcanMigration: boolean) => void;
    setRemotion: (remotion: boolean) => void;
    setShowCodeOnRegister: (showCodeOnRegister: boolean) => void;
    setRedirectCodeToMainWebsite: (redirectCodeToMainWebsite: boolean) => void;
    setNewOnboardingFlow: (newOnboardingFlow: boolean) => void;
    setShowUnauthModal: (showUnauthModal: boolean | null) => void;
    setCurrentActivationStage: (stage: number) => void;
    setFurthestActivationStage: (stage: number) => void;
    setHymoFeatures: (hymoFeatures: boolean) => void;
}

export interface AppSlice {
    app: AppValuesSlice & AppActionsSlice;
}

export const appInitialValuesState: AppValuesSlice = {
    rightSidebarWidth: 0,
    testUserLastSessionId: '',
    referralCode: '',
    internalReferrer: '',
    setupReferral: '',
    userAgent: undefined,
    utmData: {},
    lastSessionSetup: false,
    achievementCurrentChallenge: false,
    vulcanMigration: false,
    remotion: false,
    showCodeOnRegister: false,
    redirectCodeToMainWebsite: false,
    newOnboardingFlow: false,
    showUnauthModal: null,
    currentActivationStage: 1,
    furthestActivationStage: 1,
    hymoFeatures: false
};

const setSlice = (
    param: (state: AppSlice['app']) => Partial<AppSlice['app']>,
    set: (partial: AppSlice | Partial<AppSlice> | ((state: AppSlice) => AppSlice | Partial<AppSlice>)) => void
) =>
    set((state) => ({
        app: { ...state.app, ...param(state.app) }
    }));

export const createAppSlice: StateCreator<AppSlice> = (set) => ({
    app: {
        ...appInitialValuesState,
        setRightSidebarWidth: (rightSidebarWidth: number) => setSlice(() => ({ rightSidebarWidth }), set),
        setTestUserLastSessionId: (testUserLastSessionId: string) => setSlice(() => ({ testUserLastSessionId }), set),
        setReferralCode: (referralCode: string) => setSlice(() => ({ referralCode }), set),
        setInternalReferrer: (internalReferrer: string) => setSlice(() => ({ internalReferrer }), set),
        setSetupReferral: (setupReferral: string) => setSlice(() => ({ setupReferral }), set),
        setUserAgent: (userAgent: Bowser.Parser.PlatformDetails) => setSlice(() => ({ userAgent }), set),
        setUtmData: (utmData: UtmData) => setSlice(() => ({ utmData }), set),
        setLastSessionSetup: (lastSessionSetup: boolean) => setSlice(() => ({ lastSessionSetup }), set),
        setAchievementCurrentChallenge: (achievementCurrentChallenge: boolean) =>
            setSlice(() => ({ achievementCurrentChallenge }), set),
        setVulcanMigration: (vulcanMigration: boolean) => setSlice(() => ({ vulcanMigration }), set),
        setRemotion: (remotion: boolean) => setSlice(() => ({ remotion }), set),
        setShowCodeOnRegister: (showCodeOnRegister: boolean) => setSlice(() => ({ showCodeOnRegister }), set),
        setRedirectCodeToMainWebsite: (redirectCodeToMainWebsite: boolean) =>
            setSlice(() => ({ redirectCodeToMainWebsite }), set),
        setNewOnboardingFlow: (newOnboardingFlow: boolean) => setSlice(() => ({ newOnboardingFlow }), set),
        setShowUnauthModal: (showUnauthModal: boolean | null) => setSlice(() => ({ showUnauthModal }), set),
        setCurrentActivationStage: (currentActivationStage: number) =>
            setSlice(() => ({ currentActivationStage }), set),
        setFurthestActivationStage: (furthestActivationStage: number) =>
            setSlice(() => ({ furthestActivationStage }), set),
        setHymoFeatures: (hymoFeatures: boolean) => setSlice(() => ({ hymoFeatures }), set)
    }
});

export default createAppSlice as (
    set: StoreApi<AppSlice>['setState'],
    get: StoreApi<AppSlice>['getState'],
    api: StoreApi<AppSlice>
) => AppSlice;
