import { addMarker } from './actions/addMarker';
import { markerButtonClick } from './actions/markerButtonClick';
import { markerClick } from './actions/markerClick';
import { CornerMarkerActions, CornerMarkerState } from './types';

export const initialState: CornerMarkerState = {
    markers: {}
};

export const reducer = (state: CornerMarkerState, action: CornerMarkerActions): CornerMarkerState => {
    switch (action.type) {
        case 'RESET':
            return initialState;
        case 'ADD_MARKER':
            return addMarker(state, action);
        case 'MARKER_CLICK':
            return markerClick(state, action);
        case 'MARKER_BUTTON_CLICK':
            return markerButtonClick(state, action);
    }
};
