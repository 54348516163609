import { AddMarker, CornerMarkerState, SingleMarker } from '../types';

export const addMarker = (state: CornerMarkerState, action: AddMarker) => {
    const key = `${action.payload.gameId}#${action.payload.trackId}#${action.payload.segment}`;
    const markers = state.markers[key]?.markers ?? [];

    const markerRounded = Object.fromEntries(
        Object.entries(action.payload.marker).map(([key, value]) => [key, Math.round(value * 10000) / 10000])
    ) as unknown as SingleMarker;

    const newMarkers = [...markers, markerRounded];

    return {
        ...state,
        markers: {
            ...state.markers,
            [key]: { activeMarkerIndex: newMarkers.length - 1, markers: newMarkers, addingMarker: false }
        }
    };
};
