import { StateCreator, StoreApi } from 'zustand';
import { initialState, reducer } from './reducer';
import { Dispatch } from 'react';
import { CornerMarkerActions, CornerMarkerState } from './types';

export interface CornerMarkerValuesSlice {
    state: CornerMarkerState;
}

export interface CornerMarkerActionsSlice {
    dispatch: Dispatch<CornerMarkerActions>;
}

export interface CornerMarkerSlice {
    cornerMarker: CornerMarkerValuesSlice & CornerMarkerActionsSlice;
}

export const cornerMarkerInitialValuesState: CornerMarkerValuesSlice = {
    state: initialState
};

const setSlice = (
    param: (state: CornerMarkerSlice['cornerMarker']) => Partial<CornerMarkerSlice['cornerMarker']>,
    set: (
        partial:
            | CornerMarkerSlice
            | Partial<CornerMarkerSlice>
            | ((state: CornerMarkerSlice) => CornerMarkerSlice | Partial<CornerMarkerSlice>)
    ) => void
) =>
    set((state) => ({
        cornerMarker: { ...state.cornerMarker, ...param(state.cornerMarker) }
    }));

export const createCornerMarkerSlice: StateCreator<CornerMarkerSlice> = (set) => ({
    cornerMarker: {
        ...cornerMarkerInitialValuesState,
        dispatch: (action: CornerMarkerActions) =>
            setSlice((state) => ({ ...state, state: reducer(state.state, action) }), set)
    }
});

export default createCornerMarkerSlice as (
    set: StoreApi<CornerMarkerSlice>['setState'],
    get: StoreApi<CornerMarkerSlice>['getState'],
    api: StoreApi<CornerMarkerSlice>
) => CornerMarkerSlice;
