import { StateCreator, StoreApi } from 'zustand';

export type SetupFilters = {
    [filterKey: string]: string[];
};

export interface SetupsValuesSlice {
    filters: SetupFilters;
}

export interface SetupsActionsSlice {
    setFilters: (filters: SetupFilters) => void;
    resetFilters: () => void;
}

export interface SetupsSlice {
    setups: SetupsValuesSlice & SetupsActionsSlice;
}

export const setupsInitialValuesState: SetupsValuesSlice = {
    filters: {}
};

const setSlice = (
    param: (state: SetupsSlice['setups']) => Partial<SetupsSlice['setups']>,
    set: (
        partial: SetupsSlice | Partial<SetupsSlice> | ((state: SetupsSlice) => SetupsSlice | Partial<SetupsSlice>)
    ) => void
) =>
    set((state) => ({
        setups: { ...state.setups, ...param(state.setups) }
    }));

export const createSetupsSlice: StateCreator<SetupsSlice> = (set) => ({
    setups: {
        ...setupsInitialValuesState,
        setFilters: (filters: SetupFilters) => setSlice(() => ({ filters }), set),
        resetFilters: () => setSlice(() => ({ filters: {} }), set)
    }
});

export default createSetupsSlice as (
    set: StoreApi<SetupsSlice>['setState'],
    get: StoreApi<SetupsSlice>['getState'],
    api: StoreApi<SetupsSlice>
) => SetupsSlice;
