import { StateCreator, StoreApi } from 'zustand';

export type BlogFilters = {
    game: string[];
    track: string[];
    car: string[];
};

export interface BlogsValuesSlice {
    filters: BlogFilters;
}

export interface BlogsActionsSlice {
    setFilters: (filters: BlogFilters) => void;
    addFilter: (filterKey: keyof BlogFilters, filterId: string) => void;
    resetFilters: () => void;
}

export interface BlogsSlice {
    blogs: BlogsValuesSlice & BlogsActionsSlice;
}

export const blogsInitialValuesState: BlogsValuesSlice = {
    filters: {
        game: [],
        track: [],
        car: []
    }
};

const setSlice = (
    param: (state: BlogsSlice['blogs']) => Partial<BlogsSlice['blogs']>,
    set: (partial: BlogsSlice | Partial<BlogsSlice> | ((state: BlogsSlice) => BlogsSlice | Partial<BlogsSlice>)) => void
) =>
    set((state) => ({
        blogs: { ...state.blogs, ...param(state.blogs) }
    }));

export const createBlogsSlice: StateCreator<BlogsSlice> = (set) => ({
    blogs: {
        ...blogsInitialValuesState,
        setFilters: (filters: BlogFilters) => setSlice(() => ({ filters }), set),
        addFilter: (filterKey: keyof BlogFilters, filterId: string) =>
            setSlice((state) => {
                const filters = { ...state.filters };

                if (filters[filterKey]) {
                    if (filters[filterKey].find((filter) => filter === filterId)) {
                        const filterIndex = filters[filterKey].findIndex((filter) => filter === filterId);

                        if (filters[filterKey].length === 1) {
                            delete filters[filterKey];
                        } else {
                            filters[filterKey] = [
                                ...filters[filterKey].slice(0, filterIndex),
                                ...filters[filterKey].slice(filterIndex + 1)
                            ];
                        }
                    } else {
                        filters[filterKey].push(filterId);
                    }
                } else {
                    filters[filterKey] = [filterId];
                }

                return { filters };
            }, set),
        resetFilters: () =>
            setSlice(
                () => ({
                    filters: {
                        game: [],
                        track: [],
                        car: []
                    }
                }),
                set
            )
    }
});

export default createBlogsSlice as (
    set: StoreApi<BlogsSlice>['setState'],
    get: StoreApi<BlogsSlice>['getState'],
    api: StoreApi<BlogsSlice>
) => BlogsSlice;
