import { GameTracksResponse } from 'src/queries/race-engineer/useGetGameTracks';
import { GameSessionsResponse } from 'src/queries/race-engineer/useGetGameSessions';
import { UserGameResponse } from 'src/queries/race-engineer/useGetGameUser';

export type RaceEngineerState = {
    seasonId: string | null;
    trackId: string | null;
    trackSearch: string | null;
    eventSessionTypes: string[];
    firstUserUserId: string | null;
    secondUserUserId: string | null;
    firstUserLapNum: number | null;
    secondUserLapNum: number | null;
    firstUserSessionType: string | null;
    secondUserSessionType: string | null;
    firstUserSessionId: string | null;
    secondUserSessionId: string | null;
    segment?: number | null;
    tracks: GameTracksResponse['users'];
    users: UserGameResponse['users'];
    sessions: GameSessionsResponse['users'];
    setStateTimestamp?: number;
};

type ChangeSeason = {
    type: 'CHANGE_SEASON';
    data: { seasonId: string | null };
};

type ChangeTrack = {
    type: 'CHANGE_TRACK';
    data: { trackId: string | null };
};

type ChangeTrackSearch = {
    type: 'CHANGE_TRACK_SEARCH';
    data: { trackSearch: string | null };
};

type ChangeEventSessionTypes = {
    type: 'CHANGE_EVENT_SESSION_TYPES';
    data: { eventSessionTypes: string[] };
};

type ChangeFirstUser = {
    type: 'CHANGE_FIRST_USER';
    data: { firstUserUserId: string | null };
};

type ChangeSecondUser = {
    type: 'CHANGE_SECOND_USER';
    data: { secondUserUserId: string | null };
};

type ChangeFirstUserLap = {
    type: 'CHANGE_FIRST_USER_LAP';
    data: { firstUserLapNum: number | null; firstUserSessionId: string | null };
};

type ChangeSecondUserLap = {
    type: 'CHANGE_SECOND_USER_LAP';
    data: { secondUserLapNum: number | null; secondUserSessionId: string | null };
};

type ChangeFirstUserSession = {
    type: 'CHANGE_FIRST_USER_SESSION';
    data: { firstUserSessionType: string | null };
};

type ChangeSecondUserSession = {
    type: 'CHANGE_SECOND_USER_SESSION';
    data: { secondUserSessionType: string | null };
};

type ChangeSegment = {
    type: 'CHANGE_SEGMENT';
    data: { segment: number | null };
};

type AddTracks = {
    type: 'ADD_TRACKS';
    data: { tracks: GameTracksResponse['users'] };
};

type AddSessions = {
    type: 'ADD_SESSIONS';
    data: { sessions: GameSessionsResponse['users'] };
};

type SetUsers = {
    type: 'SET_USERS';
    data: { users: UserGameResponse['users'] };
};

type SetState = { type: 'SET_STATE'; data: Partial<RaceEngineerState> };
type Reset = { type: 'RESET' };

export type RaceEngineerActions =
    | ChangeSeason
    | ChangeTrack
    | ChangeTrackSearch
    | ChangeEventSessionTypes
    | ChangeFirstUser
    | ChangeSecondUser
    | ChangeFirstUserLap
    | ChangeSecondUserLap
    | ChangeFirstUserSession
    | ChangeSecondUserSession
    | ChangeSegment
    | AddTracks
    | AddSessions
    | SetState
    | SetUsers
    | Reset;

export const initialState: RaceEngineerState = {
    seasonId: null,
    trackId: null,
    trackSearch: null,
    eventSessionTypes: [],
    firstUserUserId: null,
    secondUserUserId: null,
    firstUserLapNum: null,
    secondUserLapNum: null,
    firstUserSessionType: null,
    secondUserSessionType: null,
    firstUserSessionId: null,
    secondUserSessionId: null,
    tracks: [],
    users: [],
    sessions: []
};

export const reducer = (state: RaceEngineerState, action: RaceEngineerActions): RaceEngineerState => {
    switch (action.type) {
        case 'RESET':
            return initialState;

        case 'SET_STATE': {
            return { ...state, ...action.data };
        }

        case 'CHANGE_SEASON':
            return {
                ...state,
                seasonId: action.data.seasonId
            };

        case 'CHANGE_TRACK':
            return {
                ...state,
                trackId: action.data.trackId,
                firstUserLapNum: null,
                secondUserLapNum: null,
                segment: null
            };

        case 'CHANGE_EVENT_SESSION_TYPES':
            return {
                ...state,
                eventSessionTypes: action.data.eventSessionTypes
            };

        case 'CHANGE_FIRST_USER':
            return {
                ...state,
                firstUserUserId: action.data.firstUserUserId,
                firstUserLapNum: null,
                segment: null
            };

        case 'CHANGE_SECOND_USER':
            return {
                ...state,
                secondUserUserId: action.data.secondUserUserId,
                secondUserLapNum: null,
                segment: null
            };

        case 'CHANGE_FIRST_USER_LAP':
            return {
                ...state,
                firstUserLapNum: action.data.firstUserLapNum,
                firstUserSessionId: action.data.firstUserSessionId
            };

        case 'CHANGE_SECOND_USER_LAP':
            return {
                ...state,
                secondUserLapNum: action.data.secondUserLapNum,
                secondUserSessionId: action.data.secondUserSessionId
            };

        case 'CHANGE_FIRST_USER_SESSION':
            return {
                ...state,
                firstUserSessionType: action.data.firstUserSessionType,
                firstUserLapNum: null
            };

        case 'CHANGE_SECOND_USER_SESSION':
            return {
                ...state,
                secondUserSessionType: action.data.secondUserSessionType,
                secondUserLapNum: null
            };

        case 'CHANGE_TRACK_SEARCH':
            return {
                ...state,
                trackSearch: action.data.trackSearch
            };

        case 'CHANGE_SEGMENT':
            return {
                ...state,
                segment: action.data.segment
            };

        case 'ADD_TRACKS':
            const newTracks = action.data.tracks.filter((track) => {
                return !state.tracks.find((existingTrack) => existingTrack.name === track.name);
            });

            return {
                ...state,
                tracks: [...state.tracks, ...newTracks]
            };

        case 'ADD_SESSIONS':
            const newSessions = action.data.sessions.filter((session) => {
                return !state.sessions.find((existingSession) => existingSession.name === session.name);
            });

            return {
                ...state,
                sessions: [...state.sessions, ...newSessions]
            };

        case 'SET_USERS':
            return {
                ...state,
                users: action.data.users
            };
    }
};
