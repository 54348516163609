import { StateCreator, StoreApi } from 'zustand';
import { RaceEngineerActions, RaceEngineerState, initialState, reducer } from './reducer';
import { Dispatch } from 'react';

export interface RaceEngineerValuesSlice {
    state: RaceEngineerState;
    showSelector: boolean;
}

export interface RaceEngineerActionsSlice {
    dispatch: Dispatch<RaceEngineerActions>;
    setShowSelector: (showSelector: boolean) => void;
}

export interface RaceEngineerSlice {
    raceEngineer: RaceEngineerValuesSlice & RaceEngineerActionsSlice;
}

export const raceEngineerInitialValuesState: RaceEngineerValuesSlice = {
    state: initialState,
    showSelector: false
};

const setSlice = (
    param: (state: RaceEngineerSlice['raceEngineer']) => Partial<RaceEngineerSlice['raceEngineer']>,
    set: (
        partial:
            | RaceEngineerSlice
            | Partial<RaceEngineerSlice>
            | ((state: RaceEngineerSlice) => RaceEngineerSlice | Partial<RaceEngineerSlice>)
    ) => void
) =>
    set((state) => ({
        raceEngineer: { ...state.raceEngineer, ...param(state.raceEngineer) }
    }));

export const createRaceEngineerSlice: StateCreator<RaceEngineerSlice> = (set) => ({
    raceEngineer: {
        ...raceEngineerInitialValuesState,
        dispatch: (action: RaceEngineerActions) =>
            setSlice((state) => ({ ...state, state: reducer(state.state, action) }), set),
        setShowSelector: (showSelector: boolean) => setSlice(() => ({ showSelector }), set)
    }
});

export default createRaceEngineerSlice as (
    set: StoreApi<RaceEngineerSlice>['setState'],
    get: StoreApi<RaceEngineerSlice>['getState'],
    api: StoreApi<RaceEngineerSlice>
) => RaceEngineerSlice;
