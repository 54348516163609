import { StateCreator, StoreApi } from 'zustand';
import { ContentRevealedActions, ContentRevealedState, initialState, reducer } from './reducer';
import { Dispatch } from 'react';

export interface ContentRevealedValuesSlice {
    state: ContentRevealedState;
}

export interface ContentRevealedActionsSlice {
    dispatch: Dispatch<ContentRevealedActions>;
}

export interface ContentRevealedSlice {
    contentRevealed: ContentRevealedValuesSlice & ContentRevealedActionsSlice;
}

export const contentRevealedInitialValuesState: ContentRevealedValuesSlice = {
    state: initialState
};

const setSlice = (
    param: (state: ContentRevealedSlice['contentRevealed']) => Partial<ContentRevealedSlice['contentRevealed']>,
    set: (
        partial:
            | ContentRevealedSlice
            | Partial<ContentRevealedSlice>
            | ((state: ContentRevealedSlice) => ContentRevealedSlice | Partial<ContentRevealedSlice>)
    ) => void
) =>
    set((state) => ({
        contentRevealed: { ...state.contentRevealed, ...param(state.contentRevealed) }
    }));

export const createContentRevealedSlice: StateCreator<ContentRevealedSlice> = (set) => ({
    contentRevealed: {
        ...contentRevealedInitialValuesState,
        dispatch: (action: ContentRevealedActions) =>
            setSlice((state) => ({ ...state, state: reducer(state.state, action) }), set)
    }
});

export default createContentRevealedSlice as (
    set: StoreApi<ContentRevealedSlice>['setState'],
    get: StoreApi<ContentRevealedSlice>['getState'],
    api: StoreApi<ContentRevealedSlice>
) => ContentRevealedSlice;
