import { Dispatch } from 'react';
import { StateCreator, StoreApi } from 'zustand';
import { AnalysisActions, AnalysisState, reducer } from './reducer';

export type TabTypes = 'Suggested' | 'Track Titan' | 'User';
export type ChartOptions = 'speed' | 'diffToRef';

export interface AnalysisValuesSlice {
    state: AnalysisState;
    activeTab: TabTypes;
    activeChart: ChartOptions;
    recentlySearched: string[];
    favourites: string[];
    quickTipInteractions: string[];
    quickTipsShown: string[];
    hasSessionStateLoaded: boolean;
    reportIssueModalOpen: boolean;
    gameId?: string;
    povExpanded: boolean;
}

export interface AnalysisActionsSlice {
    dispatch: Dispatch<AnalysisActions>;
    setActiveTab: (activeTab: TabTypes) => void;
    setActiveChart: (activeChart: ChartOptions) => void;
    addRecentlySearched: (username: string) => void;
    editFavourites: (username: string) => void;
    setFavourites: (favourites: string[]) => void;
    addQuickTipInteraction: (quickTipKey: string) => void;
    addQuickTipShown: (quickTipKey: string) => void;
    setHasSessionStateLoaded: (hasSessionStateLoaded: boolean) => void;
    setReportIssueModalOpen: (reportIssueModalOpen: boolean) => void;
    setGameId: (gameId?: string) => void;
    setPOVExpanded: (expanded: boolean) => void;
}

export interface AnalysisSlice {
    analysis: AnalysisValuesSlice & AnalysisActionsSlice;
}

export const analysisInitialValuesState: AnalysisValuesSlice = {
    state: { skill: undefined },
    activeTab: 'Suggested',
    activeChart: 'speed',
    recentlySearched: [],
    favourites: [],
    quickTipInteractions: [],
    quickTipsShown: [],
    hasSessionStateLoaded: false,
    reportIssueModalOpen: false,
    gameId: undefined,
    povExpanded: false
};

const setSlice = (
    param: (state: AnalysisSlice['analysis']) => Partial<AnalysisSlice['analysis']>,
    set: (
        partial:
            | AnalysisSlice
            | Partial<AnalysisSlice>
            | ((state: AnalysisSlice) => AnalysisSlice | Partial<AnalysisSlice>)
    ) => void
) =>
    set((state) => ({
        analysis: { ...state.analysis, ...param(state.analysis) }
    }));

export const createAnalysisSlice: StateCreator<AnalysisSlice> = (set) => ({
    analysis: {
        ...analysisInitialValuesState,
        dispatch: (action: AnalysisActions) =>
            setSlice((state) => ({ ...state, state: reducer(state.state, action) }), set),
        setActiveTab: (activeTab: TabTypes) => setSlice(() => ({ activeTab }), set),
        setActiveChart: (activeChart: ChartOptions) => setSlice(() => ({ activeChart }), set),
        addRecentlySearched: (username: string) =>
            setSlice((state) => {
                const usernameIndex = state.recentlySearched.indexOf(username);

                if (usernameIndex >= 0) {
                    return {
                        recentlySearched: [
                            username,
                            ...state.recentlySearched.slice(0, usernameIndex),
                            ...state.recentlySearched.slice(usernameIndex + 1, state.recentlySearched.length)
                        ]
                    };
                }

                const recentlySearched = [username, ...state.recentlySearched];

                return { recentlySearched: recentlySearched.slice(0, 20) };
            }, set),
        editFavourites: (username: string) =>
            setSlice((state) => {
                const usernameIndex = state.favourites.indexOf(username);

                if (usernameIndex >= 0) {
                    return {
                        favourites: [
                            ...state.favourites.slice(0, usernameIndex),
                            ...state.favourites.slice(usernameIndex + 1, state.favourites.length)
                        ]
                    };
                }

                const favourites = [username, ...state.favourites];

                return { favourites: favourites.slice(0, 20) };
            }, set),
        setFavourites: (favourites: string[]) => setSlice(() => ({ favourites }), set),
        addQuickTipInteraction: (quickTipKey: string) =>
            setSlice(
                (state) => ({ ...state, quickTipInteractions: [...state.quickTipInteractions, quickTipKey] }),
                set
            ),
        addQuickTipShown: (quickTipKey: string) =>
            setSlice((state) => ({ ...state, quickTipsShown: [...state.quickTipsShown, quickTipKey] }), set),
        setHasSessionStateLoaded: (hasSessionStateLoaded: boolean) => setSlice(() => ({ hasSessionStateLoaded }), set),
        setReportIssueModalOpen: (reportIssueModalOpen: boolean) => setSlice(() => ({ reportIssueModalOpen }), set),
        setGameId: (gameId?: string) => setSlice(() => ({ gameId }), set),
        setPOVExpanded: (povExpanded: boolean) => setSlice(() => ({ povExpanded }), set)
    }
});

export default createAnalysisSlice as (
    set: StoreApi<AnalysisSlice>['setState'],
    get: StoreApi<AnalysisSlice>['getState'],
    api: StoreApi<AnalysisSlice>
) => AnalysisSlice;
