import { IBreadcrumb } from 'components/Breadcrumbs';
import { StateCreator, StoreApi } from 'zustand';

export interface BreadcrumbsValuesSlice {
    breadcrumbs: IBreadcrumb[];
    hasClickedOwnLeaderboard: boolean;
}

export interface BreadcrumbsActionsSlice {
    setBreadcrumbs: (breadcrumbs: IBreadcrumb[]) => void;
    setHasClickedOwnLeaderboard: (hasClickedOwnLeaderboard: boolean) => void;
}

export interface BreadcrumbsSlice {
    breadcrumbs: BreadcrumbsValuesSlice & BreadcrumbsActionsSlice;
}

export const breadcrumbsInitialValuesState: BreadcrumbsValuesSlice = {
    breadcrumbs: [],
    hasClickedOwnLeaderboard: false
};

const setSlice = (
    param: (state: BreadcrumbsSlice['breadcrumbs']) => Partial<BreadcrumbsSlice['breadcrumbs']>,
    set: (
        partial:
            | BreadcrumbsSlice
            | Partial<BreadcrumbsSlice>
            | ((state: BreadcrumbsSlice) => BreadcrumbsSlice | Partial<BreadcrumbsSlice>)
    ) => void
) =>
    set((state) => ({
        breadcrumbs: { ...state.breadcrumbs, ...param(state.breadcrumbs) }
    }));

export const createBreadcrumbsSlice: StateCreator<BreadcrumbsSlice> = (set) => ({
    breadcrumbs: {
        ...breadcrumbsInitialValuesState,
        setBreadcrumbs: (breadcrumbs: IBreadcrumb[]) => setSlice(() => ({ breadcrumbs }), set),
        setHasClickedOwnLeaderboard: (hasClickedOwnLeaderboard: boolean) =>
            setSlice(() => ({ hasClickedOwnLeaderboard }), set)
    }
});

export default createBreadcrumbsSlice as (
    set: StoreApi<BreadcrumbsSlice>['setState'],
    get: StoreApi<BreadcrumbsSlice>['getState'],
    api: StoreApi<BreadcrumbsSlice>
) => BreadcrumbsSlice;
