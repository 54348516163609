export type ContentRevealedState = {
    dashboard: {
        sanityBannerLoaded: boolean;
        lastSessionLoaded: boolean;
        ratingHistoryLoaded: boolean;
        progressAnalysisLoaded: boolean;
    };
    session: {
        headerLoaded: boolean;
        lapsLoaded: boolean;
        sessionSummaryLoaded: boolean;
        sessionMapLoaded: boolean;
        segmentBreakdownLoaded: boolean;
    };
    segment: {
        headerLoaded: boolean;
        lapsLoaded: boolean;
        scoresLoaded: boolean;
        cockpitLoaded: boolean;
        segmentPickerLoaded: boolean;
        segmentMapLoaded: boolean;
        segmentChartLoaded: boolean;
    };
    leaderboards: {
        trackImageLoaded: boolean;
        gameTrackCarsLoaded: boolean;
        leaderboardLoaded: boolean;
    };
};

export const initialState: ContentRevealedState = {
    dashboard: {
        sanityBannerLoaded: false,
        lastSessionLoaded: false,
        ratingHistoryLoaded: false,
        progressAnalysisLoaded: false
    },
    session: {
        headerLoaded: false,
        lapsLoaded: false,
        sessionSummaryLoaded: false,
        sessionMapLoaded: false,
        segmentBreakdownLoaded: false
    },
    segment: {
        headerLoaded: false,
        lapsLoaded: false,
        scoresLoaded: false,
        cockpitLoaded: false,
        segmentPickerLoaded: false,
        segmentMapLoaded: false,
        segmentChartLoaded: false
    },
    leaderboards: {
        trackImageLoaded: false,
        gameTrackCarsLoaded: false,
        leaderboardLoaded: false
    }
};

type PageLoadData = {
    [T in keyof ContentRevealedState]?: {
        partial: keyof ContentRevealedState[T];
    };
};

type PagePartialLoad = {
    type: 'PAGE_PARTIAL_LOAD';
    data: PageLoadData;
};

type Reset = { type: 'RESET' };

export type ContentRevealedActions = PagePartialLoad | Reset;

export const reducer = (state: ContentRevealedState, action: ContentRevealedActions): ContentRevealedState => {
    switch (action.type) {
        case 'RESET':
            return initialState;

        case 'PAGE_PARTIAL_LOAD':
            const keys = Object.keys(action.data) as (keyof ContentRevealedState)[];

            if (keys.length === 0) return state;

            const pageKey = keys[0];
            const page = action.data[pageKey];

            if (page === undefined) return state;

            return {
                ...state,
                [pageKey]: {
                    ...state[pageKey],
                    [page.partial]: true
                }
            };
    }
};
