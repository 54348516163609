import { CornerMarkerState, MarkerButtonClick } from '../types';

export const markerButtonClick = (state: CornerMarkerState, action: MarkerButtonClick) => {
    const markerClickKey = `${action.payload.gameId}#${action.payload.trackId}#${action.payload.segment}`;
    const segmentConfig = state.markers[markerClickKey];

    if (!segmentConfig) {
        return {
            ...state,
            markers: {
                ...state.markers,
                [markerClickKey]: { activeMarkerIndex: 0, markers: [], addingMarker: true }
            }
        };
    }

    return {
        ...state,
        markers: {
            ...state.markers,
            [markerClickKey]: {
                ...segmentConfig,
                addingMarker: !segmentConfig.addingMarker
            }
        }
    };
};
