import { IActiveFilter } from 'components/FilterSidebar/type';
import { StateCreator, StoreApi } from 'zustand';

export interface AcademyValuesSlice {
    search: string;
    activeFilter: { [key: string]: string[] };
    sidebarFilters: IActiveFilter | null;
    sortingOption: { value: string; label: string };
}

export interface AcademyActionsSlice {
    setSearch: (search: string) => void;
    setActiveFilter: (activeFilter: { [key: string]: string[] }) => void;
    setSidebarFilters: (sidebarFilters: IActiveFilter | null) => void;
    setSortingOption: (sortingOption: { value: string; label: string }) => void;
}

export interface AcademySlice {
    academy: AcademyValuesSlice & AcademyActionsSlice;
}

export const academyInitialValuesState: AcademyValuesSlice = {
    search: '',
    activeFilter: {},
    sidebarFilters: null,
    sortingOption: { value: 'recommended', label: 'Sort by recommended' }
};

const setSlice = (
    param: (state: AcademySlice['academy']) => Partial<AcademySlice['academy']>,
    set: (
        partial: AcademySlice | Partial<AcademySlice> | ((state: AcademySlice) => AcademySlice | Partial<AcademySlice>)
    ) => void
) =>
    set((state) => ({
        academy: { ...state.academy, ...param(state.academy) }
    }));

export const createAcademySlice: StateCreator<AcademySlice> = (set) => ({
    academy: {
        ...academyInitialValuesState,
        setSearch: (search: string) => setSlice(() => ({ search }), set),
        setActiveFilter: (activeFilter: { [key: string]: string[] }) => setSlice(() => ({ activeFilter }), set),
        setSidebarFilters: (sidebarFilters: IActiveFilter | null) => setSlice(() => ({ sidebarFilters }), set),
        setSortingOption: (sortingOption: { value: string; label: string }) => setSlice(() => ({ sortingOption }), set)
    }
});

export default createAcademySlice as (
    set: StoreApi<AcademySlice>['setState'],
    get: StoreApi<AcademySlice>['getState'],
    api: StoreApi<AcademySlice>
) => AcademySlice;
