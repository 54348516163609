import { SkillRating } from 'src/types';

export type AnalysisState = {
    // Analysis query
    userLapNum?: number | 'theoreticalBestRef';
    referenceSessionKey?: string;
    referenceUserId?: string;
    referenceLapNum?: number | string;
    referenceLapId?: string;
    skill?: keyof SkillRating | 'diffToRef';
    advancedMode?: boolean;
};

type ChangeUserLap = {
    type: 'CHANGE_USER_LAP';
    data: { lapNumber: number | 'theoreticalBestRef' };
};

type ChangeRefLap = {
    type: 'CHANGE_REF_LAP';
    data: { lapNumber: string | number | undefined; lapId: string; sessionKey: string; userId: string };
};

type ChangeSkill = {
    type: 'CHANGE_SKILL';
    data: { skill: keyof SkillRating | 'diffToRef' };
};

type ChangeAdvancedMode = {
    type: 'CHANGE_ADVANCED_MODE';
    data: { enabled: boolean };
};

type SetState = { type: 'SET_STATE'; data: AnalysisState };
type Reset = { type: 'RESET' };

export type AnalysisActions = ChangeUserLap | ChangeRefLap | ChangeSkill | ChangeAdvancedMode | SetState | Reset;

export const reducer = (state: AnalysisState, action: AnalysisActions): AnalysisState => {
    switch (action.type) {
        case 'RESET':
            return {};

        case 'SET_STATE': {
            return { ...action.data };
        }

        case 'CHANGE_USER_LAP':
            return {
                ...state,
                userLapNum: action.data.lapNumber
            };

        case 'CHANGE_REF_LAP':
            return {
                ...state,
                referenceLapNum: action.data.lapNumber,
                referenceLapId: action.data.lapId,
                referenceSessionKey: action.data.sessionKey,
                referenceUserId: action.data.userId
            };

        case 'CHANGE_SKILL':
            return {
                ...state,
                skill: action.data.skill
            };

        case 'CHANGE_ADVANCED_MODE':
            return {
                ...state,
                advancedMode: action.data.enabled
            };
    }
};
