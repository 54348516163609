import { UserType } from 'src/types';
import { StateCreator, StoreApi } from 'zustand';
import { ICredentials } from '@aws-amplify/core';

export interface AuthValuesSlice {
    isOnboardingModalOpen: boolean;
    user: UserType | undefined;
    authUser: UserType | undefined;
    userCredentials: ICredentials | undefined;
    isCheckingAuth: boolean;
    isTestMode: boolean;
    testUser: UserType | undefined;
    impersonationUserId: string;
    mobileAppSource: MessageEventSource | undefined;
}

export interface AuthActionsSlice {
    setIsOnboardingModalOpen: (isOnboardingModalOpen: boolean) => void;
    setUser: (user: UserType | undefined, impersonatedUser?: boolean) => void;
    setUserCredentials: (userCredentials: ICredentials | undefined) => void;
    setIsCheckingAuth: (isCheckingAuth: boolean) => void;
    setIsTestMode: (isTestMode: boolean) => void;
    setTestUser: (testUser: UserType | undefined) => void;
    setImpersonationUserId: (impersonationUserId: string) => void;
    setMobileAppSource: (mobileAppSource: MessageEventSource | undefined) => void;
}

export interface AuthSlice {
    auth: AuthValuesSlice & AuthActionsSlice;
}

export const authInitialValuesState: AuthValuesSlice = {
    isOnboardingModalOpen: false,
    user: undefined,
    authUser: undefined,
    userCredentials: undefined,
    isCheckingAuth: true,
    isTestMode: false,
    testUser: undefined,
    impersonationUserId: '',
    mobileAppSource: undefined
};

const setSlice = (
    param: (state: AuthSlice['auth']) => Partial<AuthSlice['auth']>,
    set: (partial: AuthSlice | Partial<AuthSlice> | ((state: AuthSlice) => AuthSlice | Partial<AuthSlice>)) => void
) =>
    set((state) => ({
        auth: { ...state.auth, ...param(state.auth) }
    }));

export const createAuthSlice: StateCreator<AuthSlice> = (set) => ({
    auth: {
        ...authInitialValuesState,
        setIsOnboardingModalOpen: (isOnboardingModalOpen: boolean) => setSlice(() => ({ isOnboardingModalOpen }), set),
        setUser: (user: UserType | undefined, impersonatedUser?: boolean) =>
            setSlice((state) => ({ user, authUser: impersonatedUser ? state.authUser : user }), set),
        setUserCredentials: (userCredentials: ICredentials | undefined) => setSlice(() => ({ userCredentials }), set),
        setIsCheckingAuth: (isCheckingAuth: boolean) => setSlice(() => ({ isCheckingAuth }), set),
        setIsTestMode: (isTestMode: boolean) => setSlice(() => ({ isTestMode }), set),
        setTestUser: (testUser: UserType | undefined) => setSlice(() => ({ testUser }), set),
        setImpersonationUserId: (impersonationUserId: string) => setSlice(() => ({ impersonationUserId }), set),
        setMobileAppSource: (mobileAppSource: MessageEventSource | undefined) =>
            setSlice(() => ({ mobileAppSource }), set)
    }
});

export default createAuthSlice as (
    set: StoreApi<AuthSlice>['setState'],
    get: StoreApi<AuthSlice>['getState'],
    api: StoreApi<AuthSlice>
) => AuthSlice;
